.GameContainer {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.Toolbar {
  display: flex;
  justify-content: space-between;
}

.GameIdText {
  flex: 1;
}

.TurnText {
  flex: 1;
  text-align: center;
}

.QuitButtonContainer {
  flex: 1;
  display: flex;
  justify-content: flex-end;
}

.GameContentContainer {
  flex-grow: 1;
  margin-top: 30px;
  margin-bottom: 30px;
}

.FullHeight {
  height: 100%;
}
