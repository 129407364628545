.LobbyContent {
    display: flex;
    flex-direction: column;
  }
  
  .LobbyReadyState {
    align-self: center;
  }
  
  .LobbyPlayerList {
    margin-top: 20px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
  }