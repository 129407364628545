.ShowRoleContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.AccordionContainer {
  width: 100%;
  padding-bottom: 20px;
}

.RoleDescription {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.GameDescriptionHeading {
  padding-top: 20px;
  padding-bottom: 10px;
  font-size: large;
}

.ReadyButtonContainer {
    padding-top: 20px;
}
