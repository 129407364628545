.GameOverContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
}

.GameOverContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
}

.GameOverTitle {
  padding-bottom: 20px;
}

.GameOverPlayerListsContainer {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.GameOverPlayerList {
  display: flex;
  flex-direction: column;
  align-items: center;
}
