.SettingsHeading {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  min-height: 40px;
}

.SettingsContent {
  padding-left: 10px;
  padding-right: 10px;
}

.SettingRow {
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding-top: 5px;
  padding-bottom: 5px;
}

.SettingDescription {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.SettingSetter {
  flex: 2;
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: center;
}

.NumWerewolvesCount {
  padding-left: 5px;
  padding-right: 5px;
}

.ExtraRolesDescription {
  height: 100%;
  display: flex;
  flex-direction: column;
  padding-top: 10px;
}

.RoleSelectorSwitch {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.RoleSelectorDescription {
  padding-left: 12px;
}
