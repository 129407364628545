.VoteListContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.PlayerList {
  margin-top: 20px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  padding-bottom: 20px;
}
