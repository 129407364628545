.PlayerChip {
  display: flex;
  align-items: stretch;
  padding: 10px;
}

.DeadPlayer {
  filter: grayscale(100%);
  opacity: 0.2;
}

.PlayerChipImage {
  width: 50px;
  height: 50px;
  padding-right: 10px;
}

.PlayerNameContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
}

.VoteMarkerContainer {
  display: flex;
  flex-direction: row;
}

.VotedMarker {
  width: 18px;
  height: 18px;
}

.UnconfirmedVote {
  opacity: 0.2;
}
