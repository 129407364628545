.Container {
    height: 100%;
}

.ContainerContent {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.TextInput {
    padding: 10px;
}

.ButtonContainer {
    padding: 10px;
}