.RecentDeathsDialogContent {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.DeadMessageContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.DeadPlayerInstructions {
  padding-top: 10px;
  padding-bottom: 20px;
  text-align: center;
}
